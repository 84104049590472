import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Stages from '../../components/Stages'

const StagesLogistykaKontraktowa = ({ data }) => {
  const query = useStaticQuery(graphql`
    {
      image1: file(
        absolutePath: { regex: "/images/stages/logistyka-kontraktowa/1.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 640
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image2: file(
        absolutePath: { regex: "/images/stages/logistyka-kontraktowa/2.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 640
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image3: file(
        absolutePath: { regex: "/images/stages/logistyka-kontraktowa/3.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 640
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image4: file(
        absolutePath: { regex: "/images/stages/logistyka-kontraktowa/4.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 640
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image5: file(
        absolutePath: { regex: "/images/stages/logistyka-kontraktowa/5.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 640
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image6: file(
        absolutePath: { regex: "/images/stages/logistyka-kontraktowa/6.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 640
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  return <Stages query={query} data={data} />
}

export default StagesLogistykaKontraktowa

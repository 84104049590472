import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import ProductsLarge from '../../components/ProductsLarge'

const ProductsLogistykaKontraktowa = ({ data, minimal }) => {
  const query = useStaticQuery(graphql`
    {
      crossdocking: file(
        absolutePath: {
          regex: "/images/products/logistyka-kontraktowa/crossdocking.jpg/"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1600
            quality: 50
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      copacking: file(
        absolutePath: {
          regex: "/images/products/logistyka-kontraktowa/copacking.jpg/"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1600
            quality: 50
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      comanufacturing: file(
        absolutePath: {
          regex: "/images/products/logistyka-kontraktowa/comanufacturing.jpg/"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1600
            quality: 50
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  return <ProductsLarge query={query} data={data} minimal={minimal} />
}

export default ProductsLogistykaKontraktowa
